import React, { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Heading } from "../../Components/BackendCommon";
import { setScheduleClass, GetTeacher } from "./ApiCalls";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import './ScheduleClass.css';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

const ScheduleClass = () => {

    const today = dayjs();

    // ============ Form Working ==========
    const CourseOption = ["Course 1", "Course 2", "Course 3"];
    const [AllTeacherOption, setAllTeacherOption] = useState([])
    const CourseTypeOption = ["Paid", "Unpaid"];
    const CourseDurationOption = ["30 Min", "45 Min", "1 Hours", "1.30 Hours", "2 Hours"];

    const [ClassDate, setClassDate] = useState(today);
    const [ClassTime, setClassTime] = useState();

    const navigate = useNavigate();

    // =========== Add Teacher submit function ========
    const AddScheduleClass = async (event) => {
        event.preventDefault();
        var data = new FormData(event.currentTarget);
        var Time = dayjs(ClassTime).format("HH:mm:ss");
        setScheduleClass(data, ClassDate, Time)
            .then((response) => {
                if (response.status === 200) {
                    event.target.reset();
                    toast.success(response.data);
                    setTimeout(() => {
                        navigate('/all-schedule-class')
                    }, 2000)
                } else {
                    toast.error(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        GetTeacher()
            .then((response) => {
                console.log(response.data)
                setAllTeacherOption(response.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    const FormField = [
        {
            label: 'Select Course',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: CourseOption,
            name: "course",
        },
        {
            label: 'Select Teacher',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: AllTeacherOption,
            name: "teacher",
        },
        {
            label: 'Class Date',
            fromtype: 'input',
            type: 'date',
            placeholder: '',
            options: '',
            name: "class_date",
        },
        {
            label: 'Class Time',
            fromtype: 'input',
            type: 'time',
            placeholder: 'Select Teacher',
            options: '',
            name: "class_time",
        },
        {
            label: 'Class Duration',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: CourseDurationOption,
            name: "class_duration",
        },
        {
            label: 'Class Type',
            fromtype: 'select',
            type: '',
            placeholder: '',
            options: CourseTypeOption,
            name: "class_type",
        },
    ]

    return (
        <>
            <Breadcrumb PageName="Schedule Class" />
            <ToastContainer />
            <Box className="form-card" sx={{ mt: 3, mb: 1 }}>

                <Heading heading="Schedule Class" />

                <Divider sx={{ my: 1 }} />

                <Box component="form" autoComplete='off' onSubmit={AddScheduleClass}>
                    <Grid container spacing={2} mt={1}>
                        {FormField.map((row, id) => {
                            return (
                                <React.Fragment key={id}>
                                    <Grid item md={2}>
                                        <Typography className="custom-form-label">{row.label} <small style={{ color: 'red' }}> *</small></Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                        {row.fromtype === "input" ?
                                            <>
                                                {row.type === "date" ?
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            defaultValue={today}
                                                            disablePast
                                                            className="custom-input-filed"
                                                            name="class_date"
                                                            value={ClassDate}
                                                            onChange={(newValue) => setClassDate(newValue)}
                                                        />
                                                    </LocalizationProvider>
                                                    : row.type === "time" ?
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <MobileTimePicker
                                                                defaultValue={today}
                                                                disablePast
                                                                className="custom-input-filed"
                                                                name="class_time"
                                                                value={ClassTime}
                                                                onChange={(newValue) => setClassTime(newValue)}
                                                            />
                                                        </LocalizationProvider>
                                                        :
                                                        <input
                                                            type={row.type}
                                                            placeholder={row.placeholder}
                                                            name={row.name}
                                                            className="custom-input-filed"
                                                            required
                                                        />
                                                }
                                            </>
                                            : row.fromtype === 'select' ?
                                                <>
                                                    <Select
                                                        sx={{ width: "100%", height: "35px" }}
                                                        name={row.name}
                                                        value={row.value}
                                                        required
                                                    >
                                                        <MenuItem disabled value="">
                                                            <em>{row.label}</em>
                                                        </MenuItem>
                                                        {row.label === "Select Teacher" ?
                                                            AllTeacherOption.map((name) => (
                                                                <MenuItem key={name.fullname} value={name.fullname}>
                                                                    {name.fullname}
                                                                </MenuItem>
                                                            ))
                                                            : row.options.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </>
                                                : null}
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                    <Box sx={{ textAlign: "center" }}>
                        <Button variant="contained" type="submit" sx={{ mt: 3 }}>Schedule Class</Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ScheduleClass