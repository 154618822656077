import Axios from 'axios';
import { API_URL } from './../../App';

export const GetUpcomingClass = async (userId) => {
    try {
        const response = await Axios.post(`${API_URL}/my-class/get-upcoming-class`, {
            userId: userId
        });
        return response;
    } catch (err) {
        return err;
    }
}