import React from 'react'
import Breadcrumb from './../../Components/Breadcrumb/Breadcrumb';
import './Instructors.css'

const Instructors = () => {
  return (
    <Breadcrumb PageName="Instructors" to='/' />
  )
}

export default Instructors