import React from 'react'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import './Course.css'

const Course = () => {
  return (
    <Breadcrumb PageName="Courses" to='/' />
  )
}

export default Course