import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./CustomDatatable.css";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
});

const CustomDatatable = (props) => {

    return (
        <>
            <CacheProvider value={muiCache} className="custom-table">
                <ThemeProvider theme={createTheme()}>
                    <MUIDataTable
                        title={props.title}
                        data={props.data}
                        columns={props.columns}
                        options={{
                            textLabels: {
                                body: {
                                    noMatch: props.noMatch,
                                },
                            },
                            search: true,
                            download: true,
                            print: true,
                            viewColumns: true,
                            filter: true,
                            filterType: "dropdown",
                            // responsive,
                            // tableBodyHeight,
                            // tableBodyMaxHeight,
                        }}
                        className="custom-datatable"
                    />
                </ThemeProvider>
            </CacheProvider>
        </>
    )
}

export default CustomDatatable