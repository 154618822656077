import Axios from 'axios';
import { API_URL } from '../../App';

const getAllStudent = async () => {
    try {

        const response = await Axios.post(`${API_URL}/students/get-all-students`);
        return response;

    } catch (err) {

        return err;

    }
}
const getDeleteStudent = async (student_id) => {
    try {
        const response = await Axios.post(`${API_URL}/students/delete-student`, {
            student_id: student_id,
        });
        return response;

    } catch (err) {

        return err;

    }
}

export { getAllStudent, getDeleteStudent }