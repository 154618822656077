import * as React from 'react';
import { Box, Divider, Grid, Avatar } from "@mui/material";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Heading, Description } from "./../../Components/BackendCommon";
import "./Profile.css";
// import CustomTable from '../../Components/CustomTable/CustomTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  }),
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};


const PersonalInfo = [
  { title: "Full Name", value: "mansoor" },
  { title: "Parent's Name", value: "Bilal" },
  { title: "Contact No", value: "+91 9768344835" },
  { title: "Additional Contact Number", value: "Not available" },
  { title: "Primary Email Id", value: "bilalhalai600@yahoo.com" },
  { title: "Additional Email Id", value: "Not available" },
  { title: "Student Email Id", value: "Not available" },
  { title: "Language", value: "English" },
  { title: "Gender", value: "Not available" },
  { title: "Date of Birth", value: "Not available" },
  { title: "School/College Name", value: "Not available" },
  { title: "Current Residential Address", value: "Maharashtra Mumbai India 400070" },
  { title: "Your Timezone", value: "Asia/Calcutta +05:30 GMT" },
];

//  ============ ongoing course's data ==========
const Profile = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>

      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: { xs: "inline-block", md: "flex" } }}>
        <Box className="account-seeting-card">
          <Box className="account-setting-card-header">
            <Heading heading="Account Setting" />
          </Box>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            className='profile-tabs-wrapper'
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', textAlign: "left" }}
          >
            <Tab className='profile-tab' label="Personal Information" {...a11yProps(0)} />
            <Tab className='profile-tab' label="Communication Settings" {...a11yProps(1)} />
            <Tab className='profile-tab' label="Preferences" {...a11yProps(2)} />
            <Tab className='profile-tab' label="Settings" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="profile-tab-panel">
          <Heading heading="Personal Information" />
          <Description description="You have full control to manage your own account setting." />
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 90, height: 90 }}
              />
              <Box sx={{ ml: 2 }}>
                <Heading heading="mansoor" />
                <Description description="Points" />
              </Box>
            </Box>
            <Button variant="outlined">
              Update
            </Button>
            {/* <OutlinedButton text="Update" /> */}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2} className="personal-info-wrapper">
            {PersonalInfo.map((row, id) => {
              return (
                <Grid container spacing={2} className="personal-info" key={id}>
                  <Grid item xs={12} md={5}>
                    <Description description={row.title} />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Description description=":" />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Description description={row.value} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className="profile-tab-panel">
          <Heading heading="Communication Settings" />
          <Divider sx={{ my: 1 }} />
          <Description description="Control what you hear from us and how. This will help us improve your experience through relevant and timely communication." />
          <Box component="form">
            <Box className="communication-setting-wrapper">
              <Heading heading="How do you want us to keep in touch?" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="SMS" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Email" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="WhatsApp" />
            </Box>
          </Box>
          {/* <OutlinedButton text="Cancel" />
          <CustomContainedButton text="save" marginprops="0 1rem" bgprops="#ff9055" /> */}
        </TabPanel>
        <TabPanel value={value} index={2} className="profile-tab-panel">
          <Heading heading="Preferences" />
          <Divider sx={{ my: 1 }} />
          <Box sx={{ my: 2 }}>
            <Description description="Can we provide a substitute teacher ?" />
            <RadioGroup name="use-radio-group" defaultValue="" sx={{ display: 'contents' }}>
              <MyFormControlLabel value="Yes" label="Yes" control={<Radio />} />
              <MyFormControlLabel value="No" label="No" control={<Radio />} />
            </RadioGroup>
          </Box>
          <Box sx={{ my: 2 }}>
            <Description description="Can the teacher book classes on your behalf ?" />
            <RadioGroup name="use-radio-group" defaultValue="" sx={{ display: 'contents' }}>
              <MyFormControlLabel value="Allow" label="Allow" control={<Radio />} />
              <MyFormControlLabel value="Deny" label="Deny" control={<Radio />} />
            </RadioGroup>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3} className="profile-tab-panel">
          <Heading heading="Change Password" />
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="change-password-form" component="form">
                {/* <CustomFormGroup label="Current password" type="text" required="required" placeholder="Enter your current password" />
                <CustomFormGroup label="Current password" type="text" required="required" placeholder="Enter your current password" />
                <CustomFormGroup label="Current password" type="text" required="required" placeholder="Enter your current password" />
                <RoundedContainedButton text="Save Password" /> */}
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        {/* <TabPanel value={value} index={4} className="profile-tab-panel">
          <Heading heading="Courses" />
          <Divider sx={{ my: 1 }} />
          <CustomTable columns={OngoingCourseColumns} rows={OngoingCourseRows} />
        </TabPanel> */}
      </Box>
    </>
  )
}

export default Profile