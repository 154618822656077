import Axios from 'axios';
import { API_URL } from './../../App';

export const setScheduleClass = async (data, ClassDate, Time) => {
    try {
        const response = await Axios.post(`${API_URL}/schedule-class/add-schedule-class`, {
            course: data.get('course'),
            teacher: data.get('teacher'),
            class_date: ClassDate,
            class_time: Time,
            class_duration: data.get('class_duration'),
            class_type: data.get('class_type'),
        });
        return response;
    } catch (err) {
        return err;
    }
}

export const GetTeacher = async () => {
    try {
        const response = await Axios.post(`${API_URL}/schedule-class/get-teacher`);
        return response;
    } catch (err) {
        return err;
    }
}