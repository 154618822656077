import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home';
import "./Breadcrumb.css";

const Breadcrumb = (props) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box>
        <Link className="hometext" to={props.to}>
          <HomeIcon />
          <span className="separator">/</span>
          {props.PageName}
        </Link><br />
        <Link className="page-title">
          {props.PageName}
        </Link>
      </Box>
      {props.BtnRedirect !== undefined ?
        <Button component={Link} to={props.BtnRedirect} variant="contained">{props.btnName}</Button>
        : null}
    </Box>
  );
};

export default Breadcrumb;
