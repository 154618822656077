import React, { useEffect, useState } from 'react'
import { Description, Heading } from '../../Components/BackendCommon';
import { Alert, Box, Grid, ListItem, ListItemText, Button } from '@mui/material';
import CountingCard from "../../Components/CountingCard/CountingCard";
import CustomTable from "../../Components/CustomTable/CustomTable";
import Divider from '@mui/material/Divider';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { GetUpcomingClass } from './ApiCalls'
import "./Dashboard.css"
import dayjs from 'dayjs';

const Dashboard = () => {

  // ========= Join Class Card start ========
  const [UpcomingClass, setUpcomingClass] = useState([]);
  // const [days, setDays] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    var userId = 1;
    GetUpcomingClass(userId)
      .then((response) => {
        setUpcomingClass(response.data[0])
        // const TimerData = response.data[0]
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // console.log(UpcomingClass.class_date + " " + UpcomingClass.class_time)
    const launchDate = new Date(UpcomingClass.class_date + " " + UpcomingClass.class_time);
    // console.log(launchDate)
    const interval = setInterval(() => {
      const now = new Date();
      // console.log(now);
      const difference = launchDate - now;
      setRemainingTime(difference);
    }, 1000);

    return () => clearInterval(interval);
  })
  // ========= Join Class Card End ========

  function createsData(sname, scount, colorcode) {
    return {
      sname,
      scount,
      colorcode,
    };
  }
  const lsrccard = [
    createsData("Courses In Progress", 354, '#21b78a'),
    createsData("Completed Courses", 123, '#e47171'),
    createsData("Certifications Earned", 5362, '#7192e4'),
    createsData("Certifications Earned", 5362, '#e4ba71'),
  ];

  return (
    <>
      {/* ========= Join Class Card start ======== */}
      <Box className="upcoming-card-wrapper">
        <Alert icon={false} severity="success" className='alert-title'>Trial class scheduled! </Alert>
        <Box className="upcoming-card">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ display: "flex", p: 1 }}>
                <Box className="upcoming-card-head">
                  <Heading heading={UpcomingClass.course} />
                  <Description description={"on " + dayjs(UpcomingClass.class_date).format("ddd, DD MMM") + " at "+ dayjs(UpcomingClass.class_date + " " + UpcomingClass.class_time).format("hh:mm a")} />
                </Box>
                <Box className="countdown-timer">
                  <Description description="Your class starts in" />
                  <Box sx={{ display: "flex", justifyContent: "end", mt: .5 }}>
                    <Box className="number-container">{Math.floor(remainingTime / (1000 * 60 * 60 * 24))}</Box>
                    <span className='number-colan'> : </span>
                    <Box className="number-container">{Math.floor((remainingTime / (1000 * 60 * 60)) % 24)}</Box>
                    <span className='number-colan'> : </span>
                    <Box className="number-container">{Math.floor((remainingTime / 1000 / 60) % 60)}</Box>
                    <span className='number-colan'> : </span>
                    <Box className="number-container">{Math.floor((remainingTime / 1000) % 60)}</Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box className="upcoming-card-footer">
                <Box sx={{ display: 'flex' }}>
                  <HeadsetMicIcon />
                  <Description description="Set up your device for the class" />
                </Box>
                <Button variant="contained" >Join Your Class</Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box className="upcoming-class-instruction">
                <ListItem alignItems="center">
                  <Box className="icon-box">
                    <AccessTimeIcon />
                  </Box>
                  <ListItemText secondary="Please join the class 10 minutes early to set up your system." />
                </ListItem>
                <ListItem alignItems="center">
                  <Box className="icon-box">
                    <AccessTimeIcon />
                  </Box>
                  <ListItemText secondary="Please join the class 10 minutes early to set up your system." />
                </ListItem>
                <ListItem alignItems="center">
                  <Box className="icon-box">
                    <AccessTimeIcon />
                  </Box>
                  <ListItemText secondary="Please join the class 10 minutes early to set up your system." />
                </ListItem>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Box>
      {/* ========= Join Class Card End ======== */}
      <Box className="overview-section">
        <Heading heading="Overview" />
        <Grid container spacing={4}>
          {lsrccard.map((row, id) => {
            return (
              <Grid item xs={12} md={3} key={id}>
                <CountingCard
                  SourceName={row.sname}
                  LeadCount={row.scount}
                  BgColorCode={row.colorcode}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Box className="ongoing-course-section">
        <Grid item md={12} sx={{ mt: 4, mb: 4 }}></Grid>
        <Heading heading="Ongoing Course's" />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CustomTable />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Dashboard;